<template>
    <div class="col-lg-9">
        <!-- Card -->
        <div class="card mb-3 mb-lg-5">
        <!-- Header -->
        <div class="card-header">
            <h5 class="card-header-title">My donation</h5>

            <a class="btn btn-sm btn-ghost-secondary" href="#">
            <i class="fas fa-calendar mr-1"></i>
            View current month
            </a>
        </div>
        <!-- End Header -->

        <!-- Body -->
        <div class="card-body">
            <div class="row">
            <div class="col-md-7 mb-4 mb-md-0">
                <div class="mb-4">
                <small class="text-cap">Monthly donation</small>
                <!-- <h3 class="text-primary">€{{userProfile.donationAmount}}</h3> -->
                </div>

                <div>
                <small class="text-cap">Remaining months</small>
                <!-- <h4 v-if="userProfile.donationMonths===''">No end date specified</h4>
                <h4 v-if="userProfile.donationMonths!=''">{{userProfile.donationMonths}}</h4> -->
                </div>
            </div>

            <div class="col-md-5 text-md-right">
                <a class="btn btn-sm btn-outline-danger mr-1 mb-0 mb-md-2" href="#">Cancel</a>
                <a class="btn btn-sm btn-primary transition-3d-hover mb-0 mb-md-2" href="./page-pricing.html">Change donation</a>
            </div>
            </div>
        </div>
        <!-- End Body -->

        <hr class="my-3">

        <!-- Body -->
        <div class="card-body">
            <div class="row align-items-center flex-grow-1 mb-2">
            <div class="col">
                <h5 class="card-header-title">Donation overview</h5>
            </div>

            <div class="col-auto">
                <strong class="text-dark">€180</strong> donated locally out of €230 in total
            </div>
            </div>

            <!-- Progress -->
            <div class="progress rounded-pill mb-3">
            <div class="progress-bar" role="progressbar" style="width: 67%" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100"></div>
            <div class="progress-bar opacity" role="progressbar" style="width: 33%" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <!-- End Progress -->

            <!-- Legend Indicators -->
            <div class="list-inline">
            <div class="list-inline-item">
                <span class="legend-indicator bg-primary"></span>Local charities
            </div>
            <div class="list-inline-item">
                <span class="legend-indicator bg-primary opacity"></span>My standard charities
            </div>
            </div>
            <!-- End Legend Indicators -->
        </div>
        <!-- End Body -->
        </div>
        <!-- End Card -->

        <!-- Card -->
        <div class="card mb-3 mb-lg-5">
        <div class="card-header">
            <h5 class="card-header-title">Payment method</h5>
        </div>

        <!-- Body -->
        <div class="card-body">
            <div class="mb-4">
            <p>Cards will be charged at the end of the month. All major credit / debit cards accepted.</p>
            </div>

            <!-- List Group -->
            <ul class="list-group mb-5">
            <!-- List Item -->
            <li class="list-group-item">
                <div class="mb-2">
                <h5>Maria Williams <span class="badge badge-primary ml-1">Primary</span></h5>
                </div>

                <div class="media">
                <!-- <img class="avatar avatar-sm mr-3" src="../../../public/assets/svg/brands/mastercard.svg" alt="Image Description"> -->

                <div class="media-body">
                    <div class="row">
                    <div class="col-sm mb-3 mb-sm-0">
                        <span class="d-block text-dark">MasterCard &bull;&bull;&bull;&bull; 4242</span>
                        <small class="d-block text-muted">Checking - Expires 12/22</small>
                    </div>

                    <div class="col-sm-auto">
                        <a class="btn btn-xs btn-white mr-2" href="javascript:;" data-toggle="modal" data-target="#editCardModal">
                        <i class="fas fa-pencil-alt mr-1"></i> Edit
                        </a>
                        <button type="button" class="btn btn-xs btn-white">
                        <i class="fas fa-trash-alt mr-1"></i> Delete
                        </button>
                    </div>
                    </div>
                    <!-- End Row -->
                </div>
                </div>
            </li>
            <!-- End List Item -->

            <!-- List Item -->
            <li class="list-group-item">
                <div class="mb-2">
                <h5>Maria Williams <span class="text-danger ml-1">Expired</span></h5>
                </div>

                <div class="media">
                <!-- <img class="avatar avatar-sm mr-3" src="../../../public/assets/svg/brands/visa.svg" alt="Image Description"> -->

                <div class="media-body">
                    <div class="row">
                    <div class="col-sm mb-3 mb-sm-0">
                        <span class="d-block text-dark">Visa &bull;&bull;&bull;&bull; 9016</span>
                        <small class="d-block text-muted">Debit - Expires 04/20</small>
                    </div>

                    <div class="col-sm-auto">
                        <a class="btn btn-xs btn-white mr-2" href="javascript:;" data-toggle="modal" data-target="#editCardModal">
                        <i class="fas fa-pencil-alt mr-1"></i> Edit
                        </a>
                        <button type="button" class="btn btn-xs btn-white">
                        <i class="fas fa-trash-alt mr-1"></i> Delete
                        </button>
                    </div>
                    </div>
                    <!-- End Row -->
                </div>
                </div>
            </li>
            <!-- End List Item -->
            </ul>
            <!-- End List Group -->

            <!-- Card -->
            <a class="card card-dashed" href="javascript:;" data-toggle="modal" data-target="#addNewCardModal">
            <div class="card-body card-body-centered card-dashed-body">
                <i class="fas fa-credit-card fa-lg mb-2"></i>
                Add a new card
            </div>
            </a>
            <!-- End Card -->
        </div>
        <!-- End Body -->
        </div>
        <!-- End Card -->

        <!-- Card -->
        <div class="card">
        <!-- Header -->
        <div class="card-header">
            <h5 class="card-header-title">Payment history</h5>
        </div>
        <!-- End Header -->

        <!-- Table -->
        <div class="table-responsive">
            <table class="table table-borderless table-thead-bordered table-nowrap table-align-middle">
            <thead class="thead-light">
                <tr>
                <th>Reference</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Updated</th>
                <th>Invoice</th>
                <th style="width: 5%;"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td><a href="#">#3682303</a></td>
                <td><span class="badge badge-soft-warning">Pending</span></td>
                <td>$264</td>
                <td>22/04/2020</td>
                <td><a class="btn btn-xs btn-white" href="./page-invoice.html"><i class="fas fa-file-download mr-1"></i> PDF</a></td>
                <td><a class="btn btn-xs btn-white" href="javascript:;" data-toggle="modal" data-target="#invoiceReceiptModal"><i class="fas fa-eye mr-1"></i> Quick view</a></td>
                </tr>
                <tr>
                <td><a href="#">#2333234</a></td>
                <td><span class="badge badge-soft-success">Successful</span></td>
                <td>$264</td>
                <td>22/04/2019</td>
                <td><a class="btn btn-xs btn-white" href="./page-invoice.html"><i class="fas fa-file-download mr-1"></i> PDF</a></td>
                <td><a class="btn btn-xs btn-white" href="javascript:;" data-toggle="modal" data-target="#invoiceReceiptModal"><i class="fas fa-eye mr-1"></i> Quick view</a></td>
                </tr>
                <tr>
                <td><a href="#">#9834283</a></td>
                <td><span class="badge badge-soft-success">Successful</span></td>
                <td>$264</td>
                <td>22/04/2018</td>
                <td><a class="btn btn-xs btn-white" href="./page-invoice.html"><i class="fas fa-file-download mr-1"></i> PDF</a></td>
                <td><a class="btn btn-xs btn-white" href="javascript:;" data-toggle="modal" data-target="#invoiceReceiptModal"><i class="fas fa-eye mr-1"></i> Quick view</a></td>
                </tr>
            </tbody>
            </table>
        </div>
        <!-- End Table -->
        </div>
        <!-- End Card -->
    </div>
</template>